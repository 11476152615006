<template>
    <div>
        <v-app id="catalog-container">
            <v-dialog v-model="code_error_enable" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                        {{$t("Warning_Message.Something_Went_Wrong")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="redirectToHomePage()">
                        {{$t("Warning_Message.Home_Page")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Categories Header -->
            <div class="pt-2" v-if="filters != null && filters.length > 0">
                <category-header-mobile v-if="this.selectedSubCategoriesName.length>=0" @toggleBottomNavigation="toggleBottomNavigation" @refreshData="refreshData" :key="headerComponentKey" :page_name="catalog_data.category_name" :icon_show_status="iconStatusProp" :filters="filters" :selectedSubCategoriesName="selectedSubCategoriesName" :priceFilterRangeFromChild="priceFilterRange"></category-header-mobile>
            </div>
            

            <v-divider></v-divider>
            <div class="chipDiv" >
                <v-chip
                    v-if="showPriceChip"
                    class="chipClass"
                    close
                    close-icon="mdi-window-close"
                    label
                    @click:close="removePriceFilter"
                >Price: {{this.chipMinAmount}} - {{this.chipMaxAmount}}</v-chip>
                <v-chip
                    class="chipClass"
                    close
                    close-icon="mdi-window-close"
                    label
                    v-for="(item, index) in selectedSubCategoriesName"
                    :key="index"
                    @click:close="removeSubCategoryChip(item)"
                >{{item}}</v-chip>
            </div>
            <div v-if="productsList && productsList.length > 0" class="pa-4 products-segment" style="margin-bottom: 80px;">
                <p class="ma-0 pb-4 body-heading font-weight-semi-bold" align="left">Products</p>
                <div id='scrollDiv'>
                    <div v-if="!skeletonLoaderFlag" class="product-container">
                        <div class="py-2" v-for="product in productsList" :key="product.id" align="center">
                            <catalog-product-component @handleProductSizeChange ="handleProductSizeChange" :key="productComponentKey" :product_data="product" button_text="Buy Now"></catalog-product-component>
                        </div>
                        <div v-if="!skeletonLoaderFlag && selectedSubCategories.length <= 1 && this.exclusiveStartKey" v-infinite-scroll="loadMore"  :infinite-scroll-distance="1">
                            <div class="d-flex flex-wrap" style="padding-bottom: 90px;">
                                <div>
                                    <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                                </div>
                            </div>                                     
                        </div>
                    </div>
                    <div v-if="skeletonLoaderFlag">
                        <div class="d-flex justify-space-between flex-wrap">
                            <div>
                                <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                            </div>

                            <div>
                                <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                            </div>

                            <div>
                                <v-skeleton-loader elevation="0" type="image" width="150" height="150" class="px-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="heading" class="pa-4 d-flex justify-center align-center"></v-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex justify-center align-center no-products-found-segment">
                <p class="ma-0 body-heading font-weight-bold">{{ $t("Customer.productComponent.No_Products_found")}}</p>
            </div>

            <!-- Back to Top Icon -->
            <div v-if="productsList && productsList.length > 0" class="px-4 back-to-top-segment" align="right">
                <button class="back-to-top-button" @click="scrollToTop()">
                    <img src="https://s3iconimages.mymedicine.com.mm/BackToTop_Blue_Icon.svg" alt="back-to-top">
                </button>
            </div>
        </v-app>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils.js';
export default {
    name: 'CatalogMobilePage',
    components: {
        'catalog-product-component': () => import('../productHomeComponent.vue'),
        'category-header-mobile': () => import('../../navigationComponents/categoryHeaderMobile.vue')
    },
    props: {
        catalog_data: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            loadingNextData: true,
            skeletonLoaderFlag: false,
            productCount: 20,
            iconStatusProp: null,
            filters: null,
            productsList: null,
            productComponentKey: 0,
            headerComponentKey: 0,
            exclusiveStartKey: null,
            selectedSubCategories: [],
            selectedMaxAmount: 150000,
            selectedMinAmount: 0,
            code_error_enable: false,
            selectedSubCategoriesName: [],
            subCategoryNameIdMap: {},
            chipMinAmount: 0,
            chipMaxAmount: 150000,
            showPriceChip: false,
            priceFilterRange: true,
        }
    },
    mounted() {
        this.subCategories = this.catalog_data.subcategories;
        this.exclusiveStartKey = this.catalog_data.exclusiveStartKey;
        this.selectedSubCategories = [this.catalog_data.category_id];

        this.iconStatusProp = { sort_icon: this.subCategories.length > 0 ? true : false, filter_icon: true };
        this.catalog_data.subcategories.forEach(element => {
            this.subCategoryNameIdMap[element.cat_id] = element.cat_name;
        });
        this.filters = this.subCategories.length > 0 ? [
            { name: 'Sub Categories', filters: this.subCategories }, { name: 'Price', min_amount: 0, max_amount: 150000 }
        ] : [
            { name: 'Price', min_amount: 0, max_amount: 150000 }
        ];

        this.productsList = this.catalog_data.products;
        setTimeout(() => {
            this.loadingNextData = false;
        }, 500)
        this.headerComponentKey++;
        this.productComponentKey++;
    },
    methods: {
        removePriceFilter() {
            this.priceFilterRange = !this.priceFilterRange;
        },
        removeSubCategoryChip(item) {
            this.selectedSubCategoriesName = this.selectedSubCategoriesName.filter(ele => ele != item);
            console.log(item, this.selectedSubCategoriesName);
        },
        redirectToHomePage() {
            if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
        },
        handleProductSizeChange() {
			this.productComponentKey++;
		},
        refreshData(sortOrder, minAmount, maxAmount, categories) {
            this.selectedSubCategoriesName = categories.map(element => this.subCategoryNameIdMap[element]);
            if (minAmount !== 0 || maxAmount !== 150000) {
                this.showPriceChip = true;
                this.chipMinAmount = minAmount;
                this.chipMaxAmount = maxAmount;
            } else {
                this.showPriceChip = false;
            }
            if (categories.length)
                this.getCatalogProducts(sortOrder, minAmount, maxAmount, categories);
            else
                this.getCatalogProducts(sortOrder, minAmount, maxAmount);
        },
        loadMore() {
            if (this.loadingNextData || !this.exclusiveStartKey) return;
            let categories = null;
            this.getNextProducts(this.selectedSortOrder, this.selectedMinAmount, this.selectedMaxAmount, this.selectedSubCategories);
        },
        getNextProducts(sortOrder, minAmount=0, maxAmount=150000, categories = [this.catalog_data.category_id]) {
            this.loadingNextData = true;
            let getAvailableProductsByCategoriesRequest = {
                categoriesList: categories,
                sortOrder: sortOrder,
                minAmount: minAmount,
                maxAmount: maxAmount,
            };
            if (getAvailableProductsByCategoriesRequest.categoriesList.length === 1) {
                getAvailableProductsByCategoriesRequest['exclusiveStartKey'] = this.exclusiveStartKey;
            }
            axios_auth_instance_epharmacy_customer.get('/products/categories', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                this.productsList = this.productsList.concat(getAvailableProductsByCategoriesResponse.data.data);
                this.skeletonLoaderFlag = false;
                this.loadingNextData = false;
                this.exclusiveStartKey = getAvailableProductsByCategoriesResponse.data.exclusiveStartKey;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.loadingNextData = false;
                this.code_error_enable = true;
            });
        },
        scrollToTop() {
            const element = document.getElementById("scrollDiv");
            element.scrollIntoView({ behavior: "smooth" });
        },
        getCatalogProducts(sortOrder, minPrice, maxPrice, categories = [this.catalog_data.category_id]) {
            this.skeletonLoaderFlag = true;

            let getAvailableProductsByCategoriesRequest = {
                categoriesList: this.catalog_data.catalog_name == 'categories' ? categories : null,
                collectionList: this.catalog_data.catalog_name == 'collections' ? categories : null,
                sortOrder: sortOrder,
                minAmount: minPrice,
                maxAmount: maxPrice
            };
            this.selectedSubCategories = categories;
            this.selectedSortOrder = sortOrder;
            this.selectedMinAmount = minPrice;
            this.selectedMaxAmount = maxPrice;
            axios_auth_instance_epharmacy_customer.get('/products/' + this.catalog_data.catalog_name + '/', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                this.productsList = getAvailableProductsByCategoriesResponse.data.data;
                this.productComponentKey++;
                this.skeletonLoaderFlag = false;
                this.exclusiveStartKey = getAvailableProductsByCategoriesResponse.data.exclusiveStartKey;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.code_error_enable = true;
            });
        },
        toggleBottomNavigation(showFlag) {
            this.$emit('toggleBottomNavigation', showFlag);
        },
        getProductsOnPriceFilter(minPrice, maxPrice) {
            this.skeletonLoaderFlag = true;

            let categories = null;
            if (this.selectedSubCategories.length == this.subCategories.length) {
                categories = this.catalog_data.category_id;
            } else {
                categories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name)).map((subcategory) => parseInt(subcategory.cat_id));
            }
            let getAvailableProductsByCategoriesRequest = {
                categoriesList: [categories],
                minAmount: minPrice,
                maxAmount: maxPrice
            };

            axios_auth_instance_epharmacy_customer.get('/products/categories', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                this.productsList = getAvailableProductsByCategoriesResponse.data.data;
                this.skeletonLoaderFlag = false;
                this.code_error_enable = true;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.code_error_enable = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/classes.scss';
.chipDiv {
    margin: 10px 5px;
    max-height: 50px;
    max-width: 95%;
    overflow-x: auto !important;
    white-space: nowrap;
}
.chipClass {
    margin: 0 10px;
}
.clearFilterDiv {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

}
.clearFilterDiv2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.products-segment {
    height: 82vh;
    overflow-y: scroll;
}
.product-container {
    display: grid;
    max-height: 100vh;
    padding-bottom: 200px;
    gap: 8px;
    @media (max-width: 520px) {
        grid-template-columns: repeat(2, 1fr);
	}

    @media (min-width: 521px) and (max-width: 600px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 601px) and (max-width: 920px) {
		grid-template-columns: repeat(2, 1fr);
	}

    @media (min-width: 921px) and (max-width: 1280px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 1281px) {
		grid-template-columns: repeat(4, 1fr);
	}
}
.categories-no-overflow {
    overflow: clip;
}
.no-products-found-segment {
    min-height: 20vh;
}
.back-to-top-segment {
    position: fixed;
    bottom: 70px;
    right: 10px;
}
::-webkit-scrollbar {
    height: 2px;
}
</style>